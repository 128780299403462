<template>
  <div class="box">
    <div class="headline">个人中心</div>
    <div class="content">
      <p>【系统设置】→【个人中心】，如下图</p>
      <img src="../../assets/specificationImgs/img22.png" alt width="100%" />
      <p>可以上传自己喜欢的头像其它基本信息。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
